<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="4"
        >
          <b-form-group>
            <label>Filter By Area</label>
            <v-select
              v-model="selectedArea"
              :options="filterAreas"
              placeholder="Filter By Area"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <b-form-group>
            <label>Filter By Dealer</label>
            <v-select
              v-model="selectedDealer"
              :options="filterDealers"
              placeholder="Filter By Dealer"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <b-form-group>
            <label>Filter By Product Category</label>
            <v-select
              v-model="selectedCategory"
              :options="filterCategories"
              placeholder="Filter By Product Category"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <b-form-group>
            <label>Filter By Product</label>
            <v-select
              v-model="selectedProduct"
              :options="filterProducts"
              placeholder="Filter By Product"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <b-form-group>
            <label>Filter By Year</label>
            <v-select
              v-model="selectedYear"
              :options="filterYears"
              placeholder="Filter By Year"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <b-form-group>
            <label>Filter By Month</label>
            <v-select
              v-model="selectedMonth"
              :options="filterMonths"
              placeholder="Filter By Month"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="2"
          sm="4"
          class="d-flex align-items-center mt-2"
        >
          <b-button
            variant="secondary"
            @click="clearFilter"
          >
            Clear Filter
          </b-button>
        </b-col>
        <b-col cols="12">
          <b-table
            ref="table"
            hover
            responsive
            class="mt-2"
            :per-page="tableSettings.perPage"
            :current-page="tableSettings.currentPage"
            :items="myProvider"
            :fields="tableSettings.fields"
            :sort-by.sync="tableSettings.sortBy"
            :sort-desc.sync="tableSettings.sortDesc"
            :sort-direction="tableSettings.sortDirection"
            :filter="tableSettings.filter"
            :filter-included-fields="tableSettings.filterOn"
            show-empty
          >
            <template #cell(dcp)="data">
              {{ `${data.item.first_name} ${data.item.last_name}` }}
            </template>
            <template #cell(total_move_out)="data">
              {{ data.item.total_moveout }}
            </template>
          </b-table>
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableSettings.perPage"
                  :options="tableSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableSettings.currentPage"
                :total-rows="tableSettings.totalRows"
                :per-page="tableSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { ListService, MoveoutService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'Moveout',
  middleware: ['auth', 'abm'],
  metaInfo () {
    return {
      title: 'Moveout'
    }
  },

  mixins: [formatter],

  data () {
    return {
      isBusy: true,
      editMode: false,
      areas: [],
      selectedArea: [],
      dealers: [],
      selectedDealer: [],
      categories: [],
      selectedCategory: [],
      products: [],
      selectedProduct: [],
      years: [],
      selectedYear: [],
      months: [],
      selectedMonth: [],
      filter: {
        area: null,
        year: null,
        month: null,
        dealer: null,
        categories: null,
        products: null
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { key: 'product_name', sortable: true },
          { key: 'dealer_name', sortable: true },
          { key: 'month', sortable: true, formatter: this.changeMonth },
          { key: 'year', sortable: true },
          { key: 'beginning', sortable: true },
          { key: 'booking', sortable: true, label: 'New Sales Loading' },
          { key: 'ending', sortable: true },
          { key: 'total_move_out', sortable: true, label: 'Total Moveout' }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.editMode ? 'Edit Area' : 'Add Area'
    },
    filterAreas () {
      return [{ value: 'All', label: 'All' }].concat(this.areas)
    },
    filterDealers () {
      return [{ value: 'All', label: 'All' }].concat(this.dealers)
    },
    filterCategories () {
      return [{ value: 'All', label: 'All' }].concat(this.categories)
    },
    filterProducts () {
      return [{ value: 'All', label: 'All' }].concat(this.products)
    },
    filterYears () {
      return [{ value: 'All', label: 'All' }].concat(this.years)
    },
    filterMonths () {
      return [{ value: 'All', label: 'All' }].concat(this.months)
    }
  },

  watch: {
    'selectedArea' ({ value }) {
      this.filter = { ...this.filter, area: value }
      this.getDealers(value)
      this.$refs.table.refresh()
    },
    'selectedDealer' ({ value }) {
      this.filter = { ...this.filter, dealer: value }
      this.$refs.table.refresh()
    },

    'selectedCategory' ({ value }) {
      this.filter = { ...this.filter, categories: value }
      this.getProducts(value)
      this.$refs.table.refresh()
    },
    'selectedProduct' ({ value }) {
      this.filter = { ...this.filter, products: value }
      this.$refs.table.refresh()
    },
    'selectedYear' ({ value }) {
      this.getMonths(value)
      this.filter = { ...this.filter, year: value }
      this.$refs.table.refresh()
    },
    'selectedMonth' ({ value }) {
      this.filter = { ...this.filter, month: value }
      this.$refs.table.refresh()
    }
  },

  mounted () {
    core.index()
    this.getYears()
    this.getAreas()

    this.getCategories()
  },

  methods: {
    async myProvider (ctx) {
      const x = this.filter
      const area = x.area !== null ? x.area : ''
      const dealer = x.dealer !== null ? x.dealer : ''
      const categories = x.categories !== null ? x.categories : ''
      const products = x.products !== null ? x.products : ''
      const month = x.month !== null ? x.month : ''
      const year = x.year !== null ? x.year : ''
      const anotherQuery = `area_id=${area}&dealer_id=${dealer}&category_id=${categories}&products=${products}&month=${month}&year=${year}`
      try {
        const { data } = await MoveoutService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&${anotherQuery}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getAreas (id) {
      await ListService.getAreas('').then(({ data }) => {
        this.areas = data.map(({ id, area_name }) => {
          return { value: id, label: area_name }
        })
      })
    },

    async getDealers (areaId) {
      await ListService.getDealers(`area_id=${areaId}`).then(({ data }) => {
        this.dealers = data.map(({ id, dealer_name }) => {
          return { value: id, label: dealer_name }
        })
      })
    },

    async getCategories (id) {
      await ListService.getProductCategories().then(({ data }) => {
        this.categories = data.map(({ id, product_category_name }) => {
          return { value: id, label: product_category_name }
        })
      })
    },

    async getProducts (id) {
      await ListService.getProducts(`product_category_id=${id}`).then(({ data }) => {
        this.products = data.map(({ id, product_name }) => {
          return { value: id, label: product_name }
        })
      })
    },

    async getYears () {
      await ListService.getYear().then(({ data }) => {
        this.years = data.map(({ year }) => {
          return { value: year, label: year }
        })
      })
    },

    async getMonths (id) {
      await ListService.getMonth(`year=${id}`).then(({ data }) => {
        this.months = data.map(({ series }) => {
          return { value: series, label: this.changeMonth(series) }
        })
      })
    },

    onDistributionFilterChange () {
      this.$refs.table.refresh()
    },

    clearFilter () {
      this.filter = {
        year: null,
        month: null,
        dealer: null,
        categories: null,
        products: null
      }
      this.onDistributionFilterChange()
    }
  }
}
</script>
